import { gql } from "@apollo/client";

export const getUserQuery = gql`
  query GetUser {
    bruger_session {
      uid
      access_level
      efternavn
      fornavn
      firma_navn
      kan_repraesentere {
        id
        navn
        rolle
        seneste_dokument_aktivitet
        type
      }
      navn
      repraesenterer {
        id
        navn
        rolle
        type
      }
      role
      sagsperson {
        id
        type
        navn
      }
    }
  }
`;

const DeklarationFragment = gql`
  fragment Deklaration on Vurderingssag {
    deklarationer @include(if: $visDeklarationer) {
      brevdato
      bilag {
        dokument
        mime_type
        titel
      }
      datagrundlag @include(if: $visDatagrundlag) {
        vurderingsejendom_id_ice
        BFEnumre {
          BFEnummer
        }
        bilag {
          tilfoej_tekst {
            deklarationsmeddelelse
            dokument_id
            vedhaeftet_fil
            vurderingsmeddelelse
          }

          begrundelse {
            ejendomsvaerdi {
              deklarationsmeddelelse
              dokument_id
              vedhaeftet_fil
              vurderingsmeddelelse
            }
            grundvaerdi {
              deklarationsmeddelelse
              dokument_id
              vedhaeftet_fil
              vurderingsmeddelelse
            }
          }
          fritagelse_hel_gv {
            deklarationsmeddelelse
            dokument_id
            vedhaeftet_fil
            vurderingsmeddelelse
          }
          juridisk_kategorisering {
            deklarationsmeddelelse
            dokument_id
            vedhaeftet_fil
            vurderingsmeddelelse
          }
        }
        ejendomsnummer
        ejendomsoplysninger {
          vurderingsadresse
          beliggenhed {
            etrs89koordinatnord
            etrs89koordinatoest
          }
          ejendom_delvurderinger {
            referenceejendomme @include(if: $visReferenceejendomme) {
              generelt_vaesentlig {
                grp {
                  bad_toilet
                  tagmateriale
                  varme
                  varme_suppl
                  ydervaegsmateriale
                }
                vaegtet_areal
              }
              vurderingsejendom_id_ice
              adresse
              bynavn_suppl
              vejnavn
              husnr
              etage
              doer
              adressepunkt
              postnr
              postnr_navn
              salgsdato
              salgspris
              salgspris_fremskrevet
              fremskreven_kvm_pris
            }
            adresse
            afstand_til_cap_billede(bredde: 300) {
              kortudsnit
              stoerrelse {
                hoejde
                bredde
              }
              url
            }
            bilag {
              kvadratmeterpris {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              skoen {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              undtagelse {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
            }
            boligareal_bbr
            bygnings_anvendelse
            bygnings_anvendelseskode
            bygningsnummer
            ejl_etage
            ejl_etagegruppe
            enheds_anvendelse
            enheds_anvendelseskode
            enhedsnummer
            fletteflag {
              K8
              K9
              K10
              K12
              K23
              K72
              K73
              K76
              K77
              K78
              K79
              K92
              K93
              K122
              bbr {
                enhedens_opvarmning_tom
                bad_toilet_imputeret
                opfoerelsesaar_imputeret
                tagmateriale_imputeret
                varme_imputeret
                varme_suppl_imputeret
                ydervaegsmateriale_imputeret
              }
              boligenheder_samme_referenceejendomme_i_reakkefoelge
              data_for_foerste_boligenhed
              delvis_undtagelse
              etagegrp_traekker_ned
              etagegrp_traekker_op
              grundform_imputeret_ev
              hel_undtagelse
              hvis_sommerhusmodel_anvendt
              hvis_ejerlejlighedsmodel_anvendt
              hvis_parcel_og_raekkehusmodel_anvendt
              justering {
                afstand_jernbane_vaesentlig_ned
                afstand_jernbane_vaesentlig_op
                afstand_kyst_vaesentlig_ned
                afstand_kyst_vaesentlig_op
                afstand_motorvej_motortrafikvej_vaesentlig_ned
                afstand_motorvej_motortrafikvej_vaesentlig_op
                afstand_station_vaesentlig_ned
                afstand_station_vaesentlig_op
                afstand_stor_soe_vaesentlig_ned
                afstand_stor_soe_vaesentlig_op
                afstand_stort_vandloeb_vaesentlig_ned
                afstand_stort_vandloeb_vaesentlig_op
                afstand_trafikvej_vaesentlig_ned
                afstand_trafikvej_vaesentlig_op
                afstand_vindmoelle_vaesentlig_ned
                afstand_vindmoelle_vaesentlig_op
                bad_toilet_vaesentlig_ned
                bad_toilet_vaesentlig_op
                beliggenhed_vaesentlig_ned
                beliggenhed_vaesentlig_op
                grundareal_vaesentlig_ned
                grundareal_vaesentlig_op
                grundform_vaesentlig_ned
                grundform_vaesentlig_op
                oevrige_ned
                oevrige_op
                ombygningsaar_vaesentlig_ned
                ombygningsaar_vaesentlig_op
                opfoerelsesaar_vaesentlig_ned
                opfoerelsesaar_vaesentlig_op
                skov_areal_vaesentlig_ned
                skov_areal_vaesentlig_op
                tagmateriale_vaesentlig_ned
                tagmateriale_vaesentlig_op
                udsigt_hav_vaesentlig_ned
                udsigt_hav_vaesentlig_op
                udsigt_soe_vaesentlig_ned
                udsigt_soe_vaesentlig_op
                vaegtet_areal_vaesentlig_ned
                vaegtet_areal_vaesentlig_op
                varme_suppl_vaesentlig_ned
                varme_suppl_vaesentlig_op
                varme_vaesentlig_ned
                varme_vaesentlig_op
                ydervaegsmateriale_vaesentlig_ned
                ydervaegsmateriale_vaesentlig_op
              }
              justering_kvm_pris_op
              justering_kvm_pris_ned
              manuel_sagsbehandling_tillaeg_nedslag
              skoennet_ejendomsvaerdi_helt
              skoennet_ejendomsvaerdi_helt_oevrig
              skoennet_ejendomsvaerdi_kvm_pris
              skoennet_ejendomsvaerdi_kvm_pris_oevrig
            }
            fordelt_areal_boligdel
            fordelt_areal_oevrigt
            fritekst_delvis_undtagelse_dm
            generelt_vaesentlig {
              grp {
                bad_toilet
                tagmateriale
                varme
                varme_suppl
                varme_suppl_enhed
                ydervaegsmateriale
              }
              vaegtet_areal
            }
            grundareal
            grundform_ev
            justeringsprocenter {
              oevrige
              ydervaegsmateriale
            }
            kildedata {
              antal_badevaerelser
              antal_vandskyllende_toiletter
              badeforhold
              opvarmningsmiddel
              opvarmningsmiddel_ejl
              toiletforhold
              varmeinstallation
              varmeinstallation_ejl
            }
            kvm_pris_justeret
            ombygningsaar
            opfoerelsesaar
            ratio
            referenceejendomme {
              adresse
              adressepunkt
              bynavn_suppl
              doer
              etage
              generelt_vaesentlig {
                grp {
                  bad_toilet
                  tagmateriale
                  varme
                  varme_suppl
                  ydervaegsmateriale
                }
                vaegtet_areal
              }
              husnr
              postnr
              postnr_navn
              salgsdato
              salgspris
              salgspris_fremskrevet
              vejnavn
              vurderingsejendom_id_ice
              fremskreven_kvm_pris
            }
            skoennet_ejendomsvaerdi_helt_fritekst
            skoennet_ejendomsvaerdi_kvm_pris_fritekst
            tillaeg_nedslag {
              bilag {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              fritekst
              vaerdipaavirkende_forhold {
                forhold
              }
            }
            tillaeg_nedslag_oevrig {
              bilag {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              fritekst_dm
              vaerdipaavirkende_forhold_oevrig {
                forhold_oevrig
              }
            }
            vaegtet_areal {
              bygninger {
                areal
                arealanvendelse
                areal_vaegtet
                areal_imputeret
                vaegt
              }
              imputeret_boligareal_paa_delvurderingen
            }
            vaegtet_areal_samlet
            vaerdi_efter_tillaeg_nedslag_oevrig
          }
          fordelt_areal_boligdel_ev
          geodata {
            grundpolygon
            grundpolygoner
          }
          grundareal
          grundform
          juridisk_kategori {
            kategori_id
            kategori_tekst
            underkategori_id
            underkategori_tekst
          }
          kommune
          kommunespecifik_andel
          konkret_vaesentlig {
            adresse_afstand {
              jernbane {
                afstand
                punkt
              }
              kyst {
                afstand
                punkt
              }
              motorvej_motortrafikvej {
                afstand
                punkt
              }
              station {
                afstand
                punkt
              }
              stor_soe {
                afstand
                punkt
              }
              stort_vandloeb {
                afstand
                punkt
              }
              trafikvej {
                afstand
                punkt
              }
              vindmoelle {
                afstand
                punkt
              }
            }
            skov_areal
            udsigt_hav {
              linjer
              udsigt_meter
            }
            udsigt_soe {
              linjer
              udsigt_meter
            }
          }
          skoennet_kategorisering
          skoennet_kategorisering_fritekst
          skoennet_type
          vaegtet_areal {
            arealer {
              areal
              areal_vaegtet
              arealanvendelse
              vaegt
            }
            vaegtet_areal_samlet
          }
          vejareal
          vurderingsadresse
          tinglyst_fordelingstal
        }
        fletteflag {
          CAP {
            afstand_til_jernbane
            afstand_til_kyst
            afstand_til_motorvej_motortrafikvej
            afstand_til_station
            afstand_til_stor_soe
            afstand_til_stort_vandloeb
            afstand_til_trafikvej
            afstand_til_vindmoelle
            skov_areal
            udsigt_hav
            udsigt_soe
          }
          K4
          K5
          K24
          K32
          K48
          K69
          K74
          K86
          K87
          K114
          K121
          K169
          beliggenhed_imputeret
          beliggenhedsadresse_mangler
          begrundelse_til_GV_findes
          begrundelse_dm_findes_for_ev
          byggetilladelse_ejerbolig_mindst_1_aar
          delvis_jordrentepligt
          hvis_kvm_pris_fit_skoen_gv_boligareal_19a_stk_2
          hvis_manuelt_oprettet_delvurdering
          hvis_bilag_med_GV_komb_pcl_som
          hvis_bilag_med_GV_bilag_saerlige_ejl
          hvis_bilag_med_GV_etageboligbyggeri
          hvis_bilag_med_GV_grund_under_BFG
          hvis_bilag_med_GV_grund_under_BFG_ejl
          ev {
            delvis_undtagelse
            hvis_ejl_bygget_som_parcel
            ejendom_paa_5000kvm_i_landzone
            ev_ansaettes
            ev_uden_beskatningsmaessigt_formaal
            flere_aarsager_til_tilbageregning_ejendomsvaerdi
            foerste_boligenhed_ikke_vist_referenceejendomme
            hel_undtagelse
            hvis_ejerlejlighed
            hvis_parcelhus
            hvis_sommerhus
            hvis_tilbageregning_pba_ejerboligfordeling
            hvis_to_boligenheder
            imputeret_boligareal_paa_ejendomsniveau
            markering_for_godtgoerelse_ev
            reguleringsgrund_ev
            skoennet_ejendomsvaerdi_helt
            skoennet_ejendomsvaerdi_kvm_pris
            tilbageregning_for_samlet_vurdering_etableres
            tilbageregning_naar_ejerbolig_grundareal_over_5000_kvm_i_landzone
            tilbageregningsgrund_ev
            tilbageregning_pba_aendring_af_grundareal
            to_boligenheder_paa_delgrund_ev
            hvis_ejl_bygget_som_parcel
            offentlig_ejendom_kun_grundvaerdi
          }
          fritagelse_grundskyld_hel
          fritagelse_grundskyld_hel_manuel
          fritekst_ekstra
          grundareal_imputeret
          grundform_imputeret
          gv {
            ejendom_min_en_delvist_fritaget_delgrund_ejendomsniveau
            ejendom_offentlig_ejet_daekningsafgiftspligtig_forskelsvaerdien
            flere_delgrunde_samme_grundform
            fradrag_for_forbedringer
            fritliggende_boligenheder_paa_samme_delgrund
            grund_opdelt_i_zoner
            gv_ansaettes
            gv_uden_beskatningsmaessigt_formaal
            hvis_flere_delvurderinger_grundniveau
            manuel_regulering_gv
            markering_for_godtgoerelse_gv
            offentlig_ejendom_grundskyld_betales
            offentlig_ejendom_udlejes_erhverv
            reguleret_fradrag_pba_regulering_gv
            reguleret_fradrag_pba_tilbageregning_gv
            skoennet_grundvaerdi_helt
            skoennet_grundvaerdi_kvm_pris
            tilbageregningsgrund_gv
            uudnyttet_potentiale_moderejendom
            fritliggende_boligenheder
            ejendom_offentlig_ejet_daekningsafgiftspligtig
          }
          hvis_markeret_med_oeverst_eller_stue_med_max_2_etager
          hvis_ejl_etage_imputeret
          hvis_samvurdering
          ingen_boligenhed_delvurdering_frit_skoen
          juridisk_kategori_id_1100
          maks_en_boligenhed_delvurdering_frit_skoen
          manuel_sagsbehandling
          tomt_vurderingsforslag
          jord_i_flere_kommuner
          maskinel_kategorisering
          vejareal
          flere_delgrunde_paa_moderejendommen
          undtaget_for_vurdering
          hvis_bilag_med_GV_referencer
          parcelhusmodel_anvendt_mindst_en_ev_dv
        }
        fritekst_ekstra
        grund {
          basisaar_vurderingsejendom
          begrundelse_fritekst_dm_for_ev
          ejerlav {
            matrikler {
              matrikelnummer
            }
            navn
          }
          ejerlav_grundform
          begrundelse_til_EV
          begrundelse_til_GV
          fradrag_grundforbedringer {
            fradrag_startaar
            fradrag_stoerrelse
          }
          grund_delvurderinger {
            deljordstykkegruppe {
              aflaest_grundvaerdi_db
              aflaest_grundvaerdi_identiske_5000m2_mindre_end_eller_lig_db
              bebyggelsesprocent_dbdb
              bedste_anvendelse_dbdb
              betydning_justeringer_samlet_db
              deljordstykke_fletteflag {
                hvis_forenelig_deljordstykke
                udstykningsmuligheder
                mulighed_for_etage
                bebyggelsesprocent_dbdb_gyldig
              }
              deljordstykke_liste
              deljordstykke_navn
              foreloebig_vaerdi_udstykket_db
              mindstegrundstoerrelse_valgt_db
              mindstegrundstoerrelse_vindende
              vaerdi_samlede_justeringer_db
              vaerdi_standardhus_db
            }
            dv_deljordstykke {
              matrikkelnummer
              areal
              ejerlav
            }
            aflaest_grundvaerdi_identiske_5000m2_mindre_end_eller_lig
            annekser_areal_samlet
            anvendelse_udnyttelse_fritekst
            areal_efter_fritagelse
            bedste_anvendelse
            bedste_udnyttelsesprocent
            betragtninger {
              faktisk {
                boligenheder {
                  boligareal
                  enhedsnummer
                  faktisk_anvendelse_db
                  faktisk_etageareal_db
                }
              }
              valgt {
                boligenheder {
                  bebyggelsesprocent_db
                  betydning_justeringer_samlet_db
                  bibygninger_areal_beregnet_db
                  enhedsnummer
                  fletteflag {
                    hvis_sommerhus_db
                  }
                  boligenhed_fletteflag {
                    hvis_sommerhus_db
                  }
                  fordelt_oevrig_areal_begge_boligenheder
                  forskel_etageareal_db
                  forskel_restareal_db
                  justeringsprocent_etageareal_db
                  justeringsprocent_restareal_db
                  ratio
                  vaerdi_samlede_justeringer_db
                  vaerdi_standardhus_db
                }
              }
            }
            betydning_justeringer_samlet
            bibygninger_areal_beregnet
            bibygninger_areal_samlet
            bilag {
              fordeling_af_jordrentepligt {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              fritagelse {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              kvadratmeterpris {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              skoen {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              undtagelse {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
            }
            bibygninger {
              areal
              arealanvendelse
            }
            bygningsareal_samlet
            delgrundsnummer
            ejerlav {
              navn
              matrikler {
                matrikelnummer
              }
            }
            ev_for_standard_etageejl
            faktisk_anvendelse
            faktisk_etageareal
            fletteflag {
              K18
              K25
              K26
              K27
              K28
              K28s
              K29
              K30
              K31
              K34
              K34s
              K35
              K35s
              K36
              K43
              K43s
              K63
              K100
              K118
              K125
              K126
              K134
              K144
              K144a
              K147
              K147c
              K148
              K178
              K179
              K400
              K700
              anneksareal_paa_ejendom
              anvendelseskode_120_132_190_id_1100_2100
              anvendelseskode_130_131_140
              arealbibygninger_over_20m2
              arealbibygninger_over_25_procent
              arealbibygninger_over_50m2
              bibygningsareal_paa_ejendom
              bibygningsareal_paa_ejendom_vurdering
              del_af_delgrund_undtaget_helt_frit_skoen
              del_af_delgrund_undtaget_m2pris_frit_skoen
              delvis_jordrentepligt
              delvis_undtagelse
              en_boligenhed_paa_delgrund
              enhedsanvendelseskode_110
              enhedsanvendelseskode_120
              enhedsanvendelseskode_121
              enhedsanvendelseskode_122
              enhedsanvendelseskode_130
              enhedsanvendelseskode_131
              enhedsanvendelseskode_132
              enhedsanvendelseskode_140
              enhedsanvendelseskode_185
              enhedsanvendelseskode_190
              enhedsanvendelseskode_510
              enhedsanvendelseskode_529
              enhedsanvendelseskode_585
              enhedsanvendelseskode_590
              flere_bibygninger_paa_delgrunden
              frit_skoen_m2pris_ik_fritaget_grundskyld
              fritagelse_grundskyld_delvis
              hel_delvurdering_er_undtaget
              hel_undtagelse_gv
              helt_frit_skoen_ik_fritaget_grundskyld
              helt_fritaget_delgrund
              hvis_delgrund_i_landzone
              hvis_lokal_eller_kommune_plan_findes
              hvis_kvm_pris_skoen_gv_boligareal_19a_stk_2_dv
              oevrig_arealer_delgrund
              plan_7_i_planimputationsskema
              plan_9_i_planimputationsskema
              plan_14_i_planimputationsskema
              planlagt_anvendelse
              planlagt_udnyttelse
              rekreativt_areal_i_landzone
              samme_matrikelnummer_som_anden_delgrund
              scenarie_8
              scenarie_9
              scenarie_10
              scenarie_11
              scenarie_12
              scenarie_13
              scenarie_14
              scenarie_15
              scenarie_16
              scenarie_17
              scenarie_18
              scenarie_19
              scenarie_20
              scenarie_21
              scenarie_22
              scenarie_23
              scenarie_24
              scenarie_25
              scenarie_26
              scenarie_27
              scenarie_28
              scenarie_29
              scenarie_30
              scenarie_31
              scenarie_32
              scenarie_33
              scenarie_34
              scenarie_35
              scenarie_36
              scenarie_37
              scenarie_38
              scenarie_39
              scenarie_40
              scenarie_41
              scenarie_42
              scenarie_43
              scenarie_44
              scenarie_45
              scenarie_46
              scenarie_47
              scenarie_48
              scenarie_49
              scenarie_50
              scenarie_51
              scenarie_52
              scenarie_53
              scenarie_54
              scenarie_55
              scenarie_56
              scenarie_57
              scenarie_58
              scenarie_59
              scenarie_60
              scenarie_61
              scenarie_62
              scenarie_63
              scenarie_64
              skoennet_grundvaerdi_helt
              skoennet_grundvaerdi_kvm_pris
              to_boligenheder_paa_delgrund
              udstykningsmuligheder
              hvis_helt_frit_skoen
              skoen_paa_andel_merpotentiale_nul
              hvis_kvadratmeterpris_skoen_gv_nul
              flere_deljordstykker_paa_delgrunden
              flydende_scenarie_1
              flydende_scenarie_2
              flydende_scenarie_3
              flydende_scenarie_4
              flydende_scenarie_5
              hvis_flydende_plangrundlag
              andre_19_a_paa_moderejendom_delgrund
              hvis_skoen_tilladt_etageareal
            }
            fritagelse {
              kvm_pris_ikke_fritaget_areal
            }
            fritagelse_grundskyld_delvis_fritekst
            fritekst_delvis_undtagelse_dm
            dv_deljordstykke {
              navn
              matrikkelnummer
            }
            geodata {
              delgrund_polygon
              deljordstykke_polygoner {
                polygon
              }
            }
            grundareal_for_delgrunden
            grundvaerdi_delvis_jordrentepligt_fritekst
            mindstegrundstoerrelse_vindende
            plangrundlag {
              navn
            }
            ratio
            samlet_oevrig_areal
            skoennet_grundvaerdi_helt_fritekst
            skoennet_grundvaerdi_kvm_pris_fritekst
            tillaeg_nedslag {
              bilag {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              fritekst
              vaerdipaavirkende_forhold {
                forhold
              }
            }
            vaerdi_af_jordrentepligtige_del_af_grunden
            vaerdi_efter_tillaeg_nedslag
            vaerdi_tillaeg_nedslag_samlet
            zonestatus
          }
          fritagelse_grundskyld_hel_aarsag
          fritagelse_grundskyld_hel_fritekst
          grundvaerdi_fordelt_fritekst
          jordrentepligtig_dels_fff_andel
          matrikelnummer_grundform
          moderejendom_delvurderinger {
            moderejendom_dv_deljordstykke {
              navn
              matrikelnummer
              areal
              ejerlav
            }
            moderejendom_dv_fletteflag {
              hvis_flere_deljordstykker
              uudnyttet_potentiale_moderejendom
              hvis_flydende_plangrundlag
              flydende_scenarie_1
              flydende_scenarie_2
              flydende_scenarie_3
              flydende_scenarie_4
              flydende_scenarie_5
              hvis_delgrund_omfattet_af_beskyttelseslinjer_ejl
              samme_matrikelnummer_som_anden_delgrund_ejl
              hvis_delgrund_i_landzone
              hvis_lokal_eller_kommune_plan_findes
            }
            anvendelse
            delgrundsnummer
            ejerlav {
              matrikler {
                matrikelnummer
              }
              navn
            }
            grundareal_moderejendom
            grundareal_for_delgrunden
            moderejendom_dv_fletteflag {
              uudnyttet_potentiale_moderejendom
            }
            moderejendom_plangrundlag {
              navn
            }
            plangrundlag {
              navn
            }
            zonestatus
            moderejendom_mulige_anvendelser {
              udnyttelsesprocent_etageboligbebyggelse
            }
            geodata {
              delgrund_polygon
              deljordstykke_polygoner {
                polygon
              }
            }
          }
          reguleret_fradrag_pba_tilbage_regulering
          tilbageregnet_grundvaerdi_fritekst_dm
        }
        kommunenummer
        metainformation {
          correlation_id
          schema
        }
        modelkoersel_id
        undtagelse_ev_gv_fritekst
        vurderingsejendom_id
      }
      dokument
      dokumentgruppe
      mime_type
      titel
    }
    deklarationer @include(if: $visRichText) @skip(if: $visDeklarationer) {
      brevdato
    }
  }
`;

const InsendelserFragment = gql`
  fragment Insendelser on Vurderingssag {
    indsendelser @include(if: $visIndsendelser) {
      bilag {
        bilag_id
        mime_type
        navn
        opdateret
        oprettet
        stoerrelse
        tilstand
      }
      indhold
      indsendelse_id
      opdateret
      oprettet
      tilstand
      type
      vurderingsaar
      vurderingsejendom_id
    }
  }
`;

const TilbagebetalingssagFragment = gql`
  fragment Tilbagebetalingssag on Vurderingsejendom {
    tilbagebetalingssag {
      gentilbagebetaling {
        bilag {
          dokument
          dokument_id
          dokumentgruppe
          titel
        }
        brevdato
        datagrundlag {
          brevdata {
            datapunkter {
              aarstal_for_vurderinger_som_er_paaklaget {
                aar
              }
              tilbagebetalingsbeloeb
              tilbagebetalingsbeloeb_foer
              tilbagebetalingsbeloeb_foer_for_2013
              tilbagebetalingsbeloeb_foer_for_2014
              tilbagebetalingsbeloeb_foer_for_2015
              tilbagebetalingsbeloeb_foer_for_2016
              tilbagebetalingsbeloeb_foer_for_2017
              tilbagebetalingsbeloeb_foer_for_2018
              tilbagebetalingsbeloeb_foer_for_2019
              tilbagebetalingssags_id
              vurderingsejendoms_id
            }
            regeldata {
              ingen_kompensation_til_ejendom
              ingen_kompensation_til_modtager
              klage_over_2013
              klage_over_2014
              klage_over_2015
              klage_over_2016
              klage_over_2017
              klage_over_2018
              klage_over_2019
              kompensation_over_200
              kompensation_under_200
            }
          }
        }
        dokument
        dokumentgruppe
        mime_type
        titel
      }
      repraesentation {
        dokumenter {
          dokumentgruppe
          titel
        }
        privilegie
      }
      repraesenteret
      sag_id
      sagsdata {
        oprettet
        oprettet_af
        sagsbehandler {
          bruger
          navn
          organisatorisk_enhed
        }
        sagstype
        bfenumre
        vurderingsejendom_adresse {
          adressebetegnelse
          doer
          etage
          husnr
          postnr
          postnr_navn
          supplerende_bynavn
          vejnavn
        }
        vurderingsejendom_id
      }
      tilbagebetaling {
        bilag {
          dokument
          dokument_id
          dokumentgruppe
          titel
        }
        dokument
        brevdato
        dokumentgruppe
        mime_type
        titel
      }
      vurderingsejendom_id
    }
  }
`;

const HistoriskVurderingssagFragment = gql`
  fragment HistoriskVurderingssag on Vurderingsejendom {
    historisk_vurderingssag @include(if: $visHistoriskVurderingssag) {
      historisk_vurdering_klagefristbrev {
        bilag {
          dokument
          dokument_id
          dokumentgruppe
          titel
          udsendelsesdato
        }
        brevdato
        dokument
        dokumentgruppe
        mime_type
        titel
      }
      repraesentation {
        dokumenter {
          dokumentgruppe
          titel
        }
        privilegie
      }
      repraesenteret
      sag_id
      sagsdata {
        oprettet
        oprettet_af
        sagsbehandler {
          bruger
          navn
          organisatorisk_enhed
        }
        sagstype
        bfenumre
        vurderingsejendom_adresse {
          adressebetegnelse
          doer
          etage
          husnr
          postnr
          postnr_navn
          supplerende_bynavn
          vejnavn
        }
        vurderingsejendom_id
      }
      vurderingsejendom_id
    }
  }
`;

const SagsdataFragment = gql`
  fragment Sagsdata on Vurderingssag {
    sagsdata @include(if: $visSagsdata) {
      forrige_vurderingssag_id
      bfenumre
      vurderingstermin {
        vurderingstermindato
        vurderingsaar
      }
      frister {
        DagenEfterIndsigelsefrist
        UdloebAfgoerelsesperiode
        VM_klagefrist
        afgoerelsesperiode_slutdato
        deklarationsperiode_slutdato
      }
      oprettet
      oprettet_af
      pulje {
        kommunenummer
        postnummer
        pulje_id
      }
      sagsbehandler {
        bruger
        navn
        organisatorisk_enhed
      }
      sagsstatus {
        checkmarks
        maks_statuskode_over_tid
        statuskode
        styringskoder
      }
      sagstype
      bfenumre
      vurderingsejendom_adresse {
        adressebetegnelse
        doer
        etage
        husnr
        postnr
        postnr_navn
        supplerende_bynavn
        vejnavn
      }
      vurderingsejendom_id
      vurderingstermin {
        vurderingsaar
        vurderingstermindato
      }
    }
    sagsdata @include(if: $visRichText) @skip(if: $visSagsdata) {
      frister {
        deklarationsperiode_slutdato
        afgoerelsesperiode_slutdato
      }
      vurderingstermin {
        vurderingstermindato
        vurderingsaar
      }
    }
  }
`;

const KlagefristbrevFragment = gql`
  fragment Klagefristbrev on Vurderingssag {
    klagefristbrev @include(if: $visKlagefristbrev) {
      bilag {
        dokument
        mime_type
        titel
      }
      brevdato
      dokument
      dokumentgruppe
      mime_type
      titel
      udsendelsesdato
    }
  }
`;

const VurderingFragment = gql`
  fragment Vurdering on Vurderingssag {
    vurdering @include(if: $visVurdering) {
      brevdato
      bilag {
        dokument
        mime_type
        titel
      }
      datagrundlag {
        bilag {
          begrundelse {
            ejendomsvaerdi {
              deklarationsmeddelelse
              dokument_id
              vedhaeftet_fil
              vurderingsmeddelelse
            }
            grundvaerdi {
              deklarationsmeddelelse
              dokument_id
              vedhaeftet_fil
              vurderingsmeddelelse
            }
          }
          fritagelse_hel_gv {
            deklarationsmeddelelse
            dokument_id
            vedhaeftet_fil
            vurderingsmeddelelse
          }
          juridisk_kategorisering {
            deklarationsmeddelelse
            dokument_id
            vedhaeftet_fil
            vurderingsmeddelelse
          }
          tilfoej_tekst {
            deklarationsmeddelelse
            dokument_id
            vedhaeftet_fil
            vurderingsmeddelelse
          }
        }
        BFEnumre {
          BFEnummer
        }
        ejendomsoplysninger {
          beliggenhed {
            etrs89koordinatnord
            etrs89koordinatoest
          }
          boligdelens_fordelte_vaerdi
          boligedel_tilbageregnet_reguleret_ejendomsvaerdi_2001
          boligedel_tilbageregnet_reguleret_ejendomsvaerdi_2002
          ejendom_delvurderinger {
            adresse
            andel_i_procent
            bilag {
              skoen {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              kvadratmeterpris {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
            }
            boligareal_bbr
            boligenhedens_tilbageregnet_reguleret_ejendomsvaerdi_2001
            boligenhedens_tilbageregnet_reguleret_ejendomsvaerdi_2002
            bygnings_anvendelse
            bygnings_anvendelseskode
            bygningsnummer
            enheds_anvendelse
            enheds_anvendelseskode
            enhedsnummer
            etagegruppens_justeringsprocent
            fletteflag {
              K8
              K9
              K10
              K12
              K13
              K14
              K15
              K16
              K17
              K22
              K23
              K66
              K72
              K73
              K81
              K92
              K93
              bbr {
                enhedens_opvarmning_tom
                bad_toilet_imputeret
                opfoerelsesaar_imputeret
                tagmateriale_imputeret
                varme_imputeret
                varme_suppl_imputeret
                ydervaegsmateriale_imputeret
              }
              boligenheder_samme_referenceejendomme_i_reakkefoelge
              data_for_foerste_boligenhed
              delvis_undtagelse
              etagegrp_traekker_ned
              etagegrp_traekker_op
              hel_undtagelse
              justering {
                afstand_jernbane_vaesentlig_ned
                afstand_jernbane_vaesentlig_op
                afstand_kyst_vaesentlig_ned
                afstand_kyst_vaesentlig_op
                afstand_motorvej_motortrafikvej_vaesentlig_ned
                afstand_motorvej_motortrafikvej_vaesentlig_op
                afstand_station_vaesentlig_ned
                afstand_station_vaesentlig_op
                afstand_stor_soe_vaesentlig_ned
                afstand_stor_soe_vaesentlig_op
                afstand_stort_vandloeb_vaesentlig_ned
                afstand_stort_vandloeb_vaesentlig_op
                afstand_trafikvej_vaesentlig_ned
                afstand_trafikvej_vaesentlig_op
                afstand_vindmoelle_vaesentlig_ned
                afstand_vindmoelle_vaesentlig_op
                bad_toilet_vaesentlig_ned
                bad_toilet_vaesentlig_op
                beliggenhed_vaesentlig_ned
                beliggenhed_vaesentlig_op
                grundareal_vaesentlig_ned
                grundareal_vaesentlig_op
                grundform_vaesentlig_ned
                grundform_vaesentlig_op
                oevrige_ned
                oevrige_op
                ombygningsaar_vaesentlig_ned
                ombygningsaar_vaesentlig_op
                opfoerelsesaar_vaesentlig_ned
                opfoerelsesaar_vaesentlig_op
                skov_areal_vaesentlig_ned
                skov_areal_vaesentlig_op
                tagmateriale_vaesentlig_ned
                tagmateriale_vaesentlig_op
                udsigt_hav_vaesentlig_ned
                udsigt_hav_vaesentlig_op
                udsigt_soe_vaesentlig_ned
                udsigt_soe_vaesentlig_op
                vaegtet_areal_vaesentlig_ned
                vaegtet_areal_vaesentlig_op
                varme_suppl_vaesentlig_ned
                varme_suppl_vaesentlig_op
                varme_vaesentlig_ned
                varme_vaesentlig_op
                ydervaegsmateriale_vaesentlig_ned
                ydervaegsmateriale_vaesentlig_op
              }
              justering_kvm_pris_op
              justering_kvm_pris_ned
              manuel_sagsbehandling_tillaeg_nedslag
              manuel_sagsbehandling_tillaeg_nedslag_oevrig
              oevrig_del_i_byzone
              oevrig_del_i_landzone
              skoennet_ejendomsvaerdi_helt
              skoennet_ejendomsvaerdi_helt_oevrig
              skoennet_ejendomsvaerdi_kvm_pris
              skoennet_ejendomsvaerdi_kvm_pris_oevrig
            }
            fordelt_areal_boligdel
            fordelt_areal_oevrigt
            fordelt_vaerdi
            fritekst_delvis_undtagelse
            generelt_vaesentlig {
              grp {
                bad_toilet
                tagmateriale
                varme
                varme_suppl
                varme_suppl_enhed
                ydervaegsmateriale
              }
              vaegtet_areal
            }
            gennemsnitlig_kvm_pris_omraade
            grundareal
            justeringsprocenter {
              afstand_jernbane
              afstand_kyst
              afstand_motorvej_motortrafikvej
              afstand_station
              afstand_stor_soe
              afstand_stort_vandloeb
              afstand_trafikvej
              afstand_vindmoelle
              bad_toilet
              beliggenhed
              grundareal
              grundform
              oevrige
              ombygningsaar
              opfoerelsesaar
              skov_areal
              tagmateriale
              udsigt_hav
              udsigt_soe
              vaegtet_areal
              varme
              varme_suppl
              ydervaegsmateriale
            }
            kvm_pris_justeret
            ombygningsaar
            opfoerelsesaar
            ratio
            referenceejendomme {
              adresse
              bynavn_suppl
              doer
              etage
              fremskreven_kvm_pris
              generelt_vaesentlig {
                grp {
                  bad_toilet
                  tagmateriale
                  varme
                  varme_suppl
                  ydervaegsmateriale
                }
                vaegtet_areal
              }
              husnr
              postnr
              postnr_navn
              salgsdato
              salgspris
              salgspris_fremskrevet
              vejnavn
            }
            skoennet_ejendomsvaerdi_delvis_undtagelse
            skoennet_ejendomsvaerdi_efter
            skoennet_ejendomsvaerdi_efter_oevrig
            skoennet_ejendomsvaerdi_helt
            skoennet_ejendomsvaerdi_helt_fritekst
            skoennet_ejendomsvaerdi_helt_fritekst_oevrig
            skoennet_ejendomsvaerdi_helt_oevrig
            skoennet_ejendomsvaerdi_kvm_pris
            skoennet_ejendomsvaerdi_kvm_pris_fritekst
            skoennet_ejendomsvaerdi_kvm_pris_fritekst_oevrig
            skoennet_ejendomsvaerdi_kvm_pris_oevrig
            tillaeg_nedslag {
              fritekst
              vaerdi
              vaerdipaavirkende_forhold {
                forhold
              }
              bilag {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
            }
            tillaeg_nedslag_oevrig {
              bilag {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              fritekst_vm
              vaerdi
              vaerdipaavirkende_forhold_oevrig {
                forhold_oevrig
              }
            }
            vaegtet_areal {
              bygninger {
                areal
                areal_imputeret
                areal_vaegtet
                arealanvendelse
                vaegt
              }
              imputeret_boligareal_paa_delvurderingen
            }
            vaegtet_areal_samlet
            vaerdi_efter_tillaeg_nedslag
            vaerdi_efter_tillaeg_nedslag_oevrig
            vaerdi_foer_tillaeg_nedslag
            vaerdi_foer_tillaeg_nedslag_oevrig
            vaerdi_samlede_justeringer_kvm_pris
            vaerdi_samlede_justeringer_procent
            vaerdi_tillaeg_nedslag_samlet
            vaerdi_tillaeg_nedslag_samlet_oevrig
          }
          ejendomsvaerdi
          fritekst_tilbageregnet_ejendomsvaerdi
          grundareal
          grundform
          juridisk_kategori {
            kategori_id
            kategori_tekst
            underkategori_id
            underkategori_tekst
          }
          indekstal_2001
          indekstal_2002
          indekstal_2020
          indekstallets_myndighed
          kommune
          kommunespecifik_andel
          konkret_vaesentlig {
            adresse_afstand {
              jernbane {
                afstand
              }
              kyst {
                afstand
              }
              motorvej_motortrafikvej {
                afstand
              }
              station {
                afstand
              }
              stor_soe {
                afstand
              }
              stort_vandloeb {
                afstand
              }
              trafikvej {
                afstand
              }
              vindmoelle {
                afstand
              }
            }
            skov_areal
            udsigt_hav {
              udsigt_meter
            }
            udsigt_soe {
              udsigt_meter
            }
          }
          kvmpris_landbrugsjord
          kvmpris_rekreativ_areal
          skoennet_kategorisering
          skoennet_kategorisering_fritekst
          skoennet_type
          tilbageregnet_ejendomsvaerdi_2001
          tilbageregnet_ejendomsvaerdi_2002
          vaegtet_areal {
            arealer {
              areal
              areal_vaegtet
              arealanvendelse
              imputeret_vaerdi_for_arealanvendelsen
              vaegt
            }
            vaegtet_areal_samlet
          }
          vaerdi_oevrig
          vejareal
          vurderingsadresse
        }
        fletteflag {
          CAP {
            afstand_til_jernbane
            afstand_til_kyst
            afstand_til_motorvej_motortrafikvej
            afstand_til_station
            afstand_til_stor_soe
            afstand_til_stort_vandloeb
            afstand_til_trafikvej
            afstand_til_vindmoelle
            skov_areal
            udsigt_hav
            udsigt_soe
          }
          K4
          K5
          K7
          K20
          K24
          K32
          K46
          K47
          K48
          K49
          K50
          K52
          K62
          K69
          K70
          K71
          K74
          K75
          K87
          K108
          K113
          K114
          K160
          beliggenhed_imputeret
          beliggenhedsadresse_mangler
          begrundelse_til_EV_findes
          begrundelse_til_GV_findes
          begrundelse_vm_findes_for_gv
          byggetilladelse_ejerbolig_mindst_1_aar
          delvis_jordrentepligt
          hvis_kvm_pris_fit_skoen_gv_boligareal_19a_stk_2
          undtaget_for_vurdering
          ev {
            delvis_undtagelse
            ejendom_delvist_undtaget_delvurderinger_ejendomsniveau
            ejendom_delvist_undtaget_en_delvurdering_ejendomsniveau
            ejendom_paa_5000kvm_i_landzone
            ejendommen_omfattet_af_ejendomsvaerdiskatteloven_ev
            ev_ansaettes
            ev_uden_beskatningsmaessigt_formaal
            flere_aarsager_til_tilbageregning_ejendomsvaerdi
            foerste_boligenhed_ikke_vist_referenceejendomme
            fritekst_tilbageregning_regulering
            hel_undtagelse
            hvis_ejerlejlighed
            hvis_parcelhus
            hvis_sommerhus
            hvis_tilbageregning_pba_ejerboligfordeling
            hvis_to_boligenheder
            imputeret_boligareal_paa_ejendomsniveau
            markering_for_godtgoerelse_ev
            reguleringsgrund_ev
            skoennet_ejendomsvaerdi_helt
            skoennet_ejendomsvaerdi_kvm_pris
            tilbageregning_for_samlet_vurdering_etableres
            tilbageregning_naar_ejerbolig_grundareal_over_5000_kvm_i_landzone
            tilbageregning_pba_aendring_af_grundareal
            tilbageregning_pba_ejendom_omfattet_af_pligt
            tilbageregning_pba_opfoert_nybyggeri
            tilbageregningsgrund_ev
            hvis_ejl_bygget_som_parcel
            offentlig_ejendom_kun_grundvaerdi
          }
          fritagelse_grundskyld_hel
          fritagelse_grundskyld_hel_manuel
          fritekst_ekstra
          grundareal_imputeret
          grundform_imputeret
          gv {
            ejendom_min_en_delvist_fritaget_delgrund_ejendomsniveau
            ejendom_min_en_hel_fritaget_delgrund
            ejendom_offentlig_ejet_daekningsafgiftspligtig_forskelsvaerdien
            flere_delgrunde_samme_grundform
            flere_fradrag_for_grundforbedringer
            flere_fradrag_reguleres_pba_tilbageregning
            fradrag_for_forbedringer
            fritekstfelt_aktiveret_gv
            fritliggende_boligenheder
            fritliggende_boligenheder_paa_samme_delgrund
            grund_opdelt_i_zoner
            gv_ansaettes
            gv_uden_beskatningsmaessigt_formaal
            hvis_dobbelthus
            hvis_flere_delvurderinger_grundniveau
            manuel_fastsaettelse_FFF
            manuel_regulering_gv
            markering_for_godtgoerelse_gv
            offentlig_ejendom_grundskyld_betales
            offentlig_ejendom_udlejes_erhverv
            reguleret_fradrag_pba_regulering_gv
            reguleret_fradrag_pba_tilbageregning_gv
            skoennet_grundvaerdi_helt
            skoennet_grundvaerdi_kvm_pris
            tilbageregning_pba_samlet_vurdering_etableres_aendres_ophoerer
            tilbageregningsgrund_gv
            to_familiehus_vandret_skel_paa_grunden
            uudnyttet_potentiale_moderejendom
            ejendom_offentlig_ejet_daekningsafgiftspligtig
          }
          hvis_samvurdering
          ingen_boligenhed_delvurdering_frit_skoen
          juridisk_kategori_id_1100
          maks_en_boligenhed_delvurdering_frit_skoen
          manuel_sagsbehandling
          tomt_vurderingsforslag
          jord_i_flere_kommuner
          maskinel_kategorisering
          planlagt_anvendelse_udnyttelse_fra_bygningsregelmentet
          vejareal
          undtaget_for_vurdering
          parcelhusmodel_anvendt_mindst_en_ev_dv
        }
        fritekst_ekstra
        grund {
          moderejendom_delvurderinger {
            ejerlav {
              matrikler {
                matrikelnummer
              }
              navn
            }
          }
          aflaest_gv_vaerdi_ud_fra_gvk
          basisaar_vurderingsejendom
          ejerlav {
            matrikler {
              matrikelnummer
            }
            navn
          }
          ejerlav_grundform
          begrundelse_til_EV
          begrundelse_til_GV
          begrundelse_fritekst_vm_for_gv
          fradrag_grundforbedringer {
            fradrag_startaar
            grundvaerdi
            vaerdi_2020
          }
          fradrag_grundforbedringer_samlet_vaerdi
          fradrag_grundforbedringer_vardi_minus_fritagelse
          fritagelse_grundskyld_hel_aarsag
          fritagelse_grundskyld_hel_fritekst
          fritekst
          grund_delvurderinger {
            aflaest_grundvaerdi
            aflaest_grundvaerdi_foerste_5000m2
            aflaest_grundvaerdi_identiske_5000m2
            aflaest_grundvaerdi_identiske_5000m2_mindre_end_eller_lig
            annekser_areal_samlet
            aflaest_gv_vaerdi_ud_fra_gvk_sejl
            antal_mulige_udstykninger
            anvendelse_udnyttelse_fritekst
            areal_efter_fritagelse
            areal_resterende_del
            areal_resterende_del_identiske_delbetragt
            bebyggelsesprocent
            bedste_anvendelse
            bedste_udnyttelse
            boligareal_bbr_gv
            betragtninger {
              faktisk {
                boligenheder {
                  boligareal
                  enhedsnummer
                  faktisk_anvendelse_db
                  faktisk_etageareal_db
                }
              }
              valgt {
                boligenheder {
                  aflaest_grundvaerdi_db
                  areal_resterende_del_db
                  bebyggelsesprocent_db
                  bedste_anvendelse_db
                  bedste_udnyttelse_db
                  betydning_justeringer_samlet_db
                  bibygninger_areal_beregnet_db
                  enhedsnummer
                  fletteflag {
                    hvis_sommerhus_db
                  }
                  fordelt_oevrig_areal_begge_boligenheder
                  foreloebig_grundvaerdi_db
                  forskel_etageareal_db
                  forskel_restareal_db
                  grundareal
                  justeringsprocent_etageareal_db
                  justeringsprocent_restareal_db
                  ratio
                  restareal_db
                  vaerdi_af_landbrugsjord
                  vaerdi_samlede_justeringer_db
                  vaerdi_standardhus_db
                  vejareal_for_delbetragtning
                }
              }
            }
            betydning_justeringer_samlet
            bibygninger {
              areal
              arealanvendelse
            }
            bibygninger_areal_beregnet
            bibygninger_areal_samlet
            bilag {
              valg_af_beregning {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              kvadratmeterpris {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              skoen {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              fritagelse {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              fordeling_af_jordrentepligt {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
            }
            bygningsareal_samlet
            bygningsvaerdi
            bygningsvaerdi_sejl
            bygningsvaerdi_standardejl_pr_kvm
            bygningsvaerdi_standardejl_pr_kvm_sejl
            delgrundsnummer
            ejerlav {
              matrikler {
                matrikelnummer
              }
              navn
            }
            ev_for_standard_etageejl
            ev_for_standard_etageejl_sejl
            ev_standardejl_pr_kvadratmeter
            ev_standardejl_pr_kvadratmeter_sejl
            faktisk_anvendelse
            faktisk_etageareal
            fletteflag {
              K18
              K25
              K26
              K27
              K28
              K28s
              K29
              K30
              K31
              K33
              K34
              K34s
              K35
              K35s
              K36
              K37
              K38
              K42
              K43
              K43s
              K54
              K55
              K59
              K60
              K63
              K65
              K84
              K88
              K89
              K91
              K94
              K95
              K96
              K97
              K98
              K99
              K100
              K101
              K102
              K103
              K104
              K105
              K106
              K107
              K109
              K110
              K111
              K112
              K118
              K700
              anneksareal_paa_ejendom
              anvendelseskode_120_132_190_id_1100_2100
              anvendelseskode_130_131_140
              arealbibygninger_over_20m2
              arealbibygninger_over_25_procent
              arealbibygninger_over_50m2
              bibygningsareal_paa_ejendom
              bibygningsareal_paa_ejendom_vurdering
              del_af_delgrund_undtaget_helt_frit_skoen
              del_af_delgrund_undtaget_m2pris_frit_skoen
              delgrund_over_5000m2
              delvis_jordrentepligt
              delvis_undtagelse
              en_boligenhed_paa_delgrund
              enhedsanvendelseskode_110
              enhedsanvendelseskode_120
              enhedsanvendelseskode_121
              enhedsanvendelseskode_122
              enhedsanvendelseskode_130
              enhedsanvendelseskode_131
              enhedsanvendelseskode_132
              enhedsanvendelseskode_140
              enhedsanvendelseskode_185
              enhedsanvendelseskode_190
              enhedsanvendelseskode_510
              enhedsanvendelseskode_529
              enhedsanvendelseskode_585
              enhedsanvendelseskode_590
              flere_bibygninger_paa_delgrunden
              frit_skoen_m2pris_ik_fritaget_grundskyld
              fritagelse_grundskyld_delvis
              grund_for_boligenheder_overstiger_5000m2
              grundvaerdi_justeret_ned
              grundvaerdi_justeret_op
              hel_delvurdering_er_undtaget
              hel_undtagelse_gv
              helt_frit_skoen_ik_fritaget_grundskyld
              helt_fritaget_delgrund
              hvis_kun_en_frastykning
              hvis_kvm_pris_skoen_gv_boligareal_19a_stk_2_dv
              manuelt_skifte_planlagt_faktisk_anvendelse_udnyttelse
              oevrig_arealer_delgrund
              planlagt_anvendelse
              planlagt_udnyttelse
              rekreativt_areal
              rekreativt_areal_i_landzone
              samme_matrikelnummer_som_anden_delgrund
              scenarie_1
              scenarie_1_1
              scenarie_2
              scenarie_3
              scenarie_3_1
              scenarie_4
              scenarie_5
              scenarie_6
              scenarie_6_1
              scenarie_7
              scenarie_7_1
              scenarie_8
              scenarie_9
              scenarie_9_1
              scenarie_10
              scenarie_11
              scenarie_12
              scenarie_12_1
              scenarie_13
              scenarie_14
              scenarie_14_1
              scenarie_15
              scenarie_15_1
              scenarie_16
              scenarie_17
              scenarie_18
              scenarie_19
              scenarie_20
              scenarie_21
              scenarie_22
              scenarie_23
              scenarie_24
              scenarie_25
              scenarie_26
              scenarie_27
              scenarie_27_1
              scenarie_28
              scenarie_28_1
              scenarie_29
              scenarie_30
              scenarie_30_1
              scenarie_31
              scenarie_31_1
              scenarie_32
              scenarie_33
              scenarie_33_1
              scenarie_34
              scenarie_34_1
              scenarie_35
              scenarie_35_1
              scenarie_36
              scenarie_36_1
              scenarie_37
              scenarie_38
              scenarie_39
              scenarie_39_1
              scenarie_40
              scenarie_40_1
              scenarie_41
              scenarie_42
              scenarie_43
              scenarie_44
              scenarie_45
              scenarie_46
              scenarie_47
              scenarie_48
              scenarie_49
              scenarie_50
              scenarie_51
              scenarie_52
              scenarie_53
              scenarie_54
              scenarie_55
              scenarie_56
              scenarie_57
              scenarie_58
              scenarie_59
              scenarie_60
              scenarie_61
              scenarie_62
              scenarie_63
              scenarie_64
              skoennet_ejendomsvaerdi_helt_gv
              skoennet_ejendomsvaerdi_kvm_pris_gv
              skoennet_grundvaerdi_helt
              skoennet_grundvaerdi_kvm_pris
              to_boligenheder_paa_delgrund
              udstykning_over_5000m2_delgrund
              udstykningsmuligheder
              vejareal_paa_delgrund
              hvis_helt_frit_skoen
              flere_deljordstykker_paa_delgrunden
              flydende_scenarie_1
              flydende_scenarie_2
              flydende_scenarie_3
              flydende_scenarie_4
              flydende_scenarie_5
              andre_19_a_paa_moderejendom_delgrund
              hvis_skoen_tilladt_etageareal
            }
            foreloebig_grundvaerdi
            foreloebig_grundvaerdi_foerste_5000
            foreloebig_grundvaerdi_foerste_5000_identiske_db
            foreloebig_vaerdi_udstykket
            forskel_etageareal
            forskel_restareal
            fritagelse {
              kvm_pris_ikke_fritaget_areal
            }
            fritagelse_grundskyld_delvis_fritekst
            grundareal_for_delgrunden
            grundvaerdi_efter_fritagelse
            grundvaerdi_pr_kvm_grund
            grundvaerdi_pr_kvm_grund_sejl
            jordrentepligt_fordeling {
              fritekst
            }
            justeringsprocent_etageareal
            justeringsprocent_restareal
            mindstegrundstoerrelse_valgt
            plangrundlag {
              navn
            }
            restareal
            samlet_oevrig_areal
            skoennet_grundvaerdi_efter
            skoennet_grundvaerdi_helt
            skoennet_grundvaerdi_helt_fritekst
            skoennet_grundvaerdi_kvm_pris
            skoennet_grundvaerdi_kvm_pris_fritekst
            stoerrelse_rekreativt_areal
            tillaeg_nedslag {
              fritekst
              vaerdi
              vaerdipaavirkende_forhold {
                forhold
              }
              bilag {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
            }
            undtagelse {
              grundvaerdi_efter_skoennet_m2pris
              grundvaerdi_fritekst
              skoennet_grundvaerdi
              undtagelse_kvm_pris
            }
            vaerdi_af_jordrentepligtige_del_af_grunden
            vaerdi_af_landbrugsjord
            vaerdi_efter_tillaeg_nedslag
            vaerdi_rekreativt_areal
            vaerdi_samlede_justeringer
            vaerdi_standardhus
            vaerdi_tillaeg_nedslag_samlet
            vejareal_for_delgrunden
            zonestatus
          }
          grundvaerdi
          grundvaerdi_efter_fritagelse
          grundvaerdi_etageejl_uden_pot_tillaeg_nedslag
          jordrentepligt_fritekst
          jordrentepligtig_dels_fff_andel
          jordrentepligtvaerdi
          matrikelnummer_grundform
          reguleret_fradrag_pba_tilbage_regulering
          tilbageregnet_grundvaerdi
          tilbageregnet_grundvaerdi_fritekst
          vaerdi_samlet_fradrag_pba_tilbage_regulering
          daekningsafgiftspligtig_grundvaerdi
        }
        metainformation {
          correlation_id
          schema
        }
        undtagelse {
          fritekst
        }
        vurderingsejendom_id
      }
      dokument
      dokumentgruppe
    }
    vurdering @include(if: $visRichText) {
      brevdato
      datagrundlag {
        grund {
          grundvaerdi
          grund_delvurderinger {
            antal_mulige_udstykninger
            bedste_anvendelse
            bedste_udnyttelse
            foreloebig_vaerdi_udstykket
            mindstegrundstoerrelse_valgt
          }
        }
        ejendomsoplysninger {
          ejendomsvaerdi
          grundareal
        }
      }
    }
  }
`;

const RevurderingssagerFragment = gql`
  fragment Revurderingssager on Vurderingssag {
    revurderingssager @include(if: $visRevurderingssager) {
      repraesentation {
        dokumenter {
          dokumentgruppe
          titel
        }
        privilegie
      }
      repraesenteret
      revurdering {
        datagrundlag {
          bilag {
            begrundelse {
              ejendomsvaerdi {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
              grundvaerdi {
                deklarationsmeddelelse
                dokument_id
                vedhaeftet_fil
                vurderingsmeddelelse
              }
            }
            fritagelse_hel_gv {
              deklarationsmeddelelse
              dokument_id
              vedhaeftet_fil
              vurderingsmeddelelse
            }
            juridisk_kategorisering {
              deklarationsmeddelelse
              dokument_id
              vedhaeftet_fil
              vurderingsmeddelelse
            }
            tilfoej_tekst {
              deklarationsmeddelelse
              dokument_id
              vedhaeftet_fil
              vurderingsmeddelelse
            }
          }
          BFEnumre {
            BFEnummer
          }
          ejendomsoplysninger {
            beliggenhed {
              etrs89koordinatnord
              etrs89koordinatoest
            }
            boligdelens_fordelte_vaerdi
            boligedel_tilbageregnet_reguleret_ejendomsvaerdi_2001
            boligedel_tilbageregnet_reguleret_ejendomsvaerdi_2002
            ejendom_delvurderinger {
              adresse
              andel_i_procent
              bilag {
                skoen {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
                kvadratmeterpris {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
              }
              boligareal_bbr
              boligenhedens_tilbageregnet_reguleret_ejendomsvaerdi_2001
              boligenhedens_tilbageregnet_reguleret_ejendomsvaerdi_2002
              bygnings_anvendelse
              bygnings_anvendelseskode
              bygningsnummer
              ejl_etage
              ejl_etagegruppe
              enheds_anvendelse
              enheds_anvendelseskode
              enhedsnummer
              fletteflag {
                K8
                K9
                K10
                K12
                K13
                K14
                K15
                K16
                K17
                K22
                K23
                K66
                K72
                K73
                K81
                K92
                K93
                bbr {
                  enhedens_opvarmning_tom
                  bad_toilet_imputeret
                  opfoerelsesaar_imputeret
                  tagmateriale_imputeret
                  varme_imputeret
                  varme_suppl_imputeret
                  ydervaegsmateriale_imputeret
                }
                boligenheder_samme_referenceejendomme_i_reakkefoelge
                data_for_foerste_boligenhed
                delvis_undtagelse
                etagegrp_traekker_ned
                etagegrp_traekker_op
                hel_undtagelse
                justering {
                  afstand_jernbane_vaesentlig_ned
                  afstand_jernbane_vaesentlig_op
                  afstand_kyst_vaesentlig_ned
                  afstand_kyst_vaesentlig_op
                  afstand_motorvej_motortrafikvej_vaesentlig_ned
                  afstand_motorvej_motortrafikvej_vaesentlig_op
                  afstand_station_vaesentlig_ned
                  afstand_station_vaesentlig_op
                  afstand_stor_soe_vaesentlig_ned
                  afstand_stor_soe_vaesentlig_op
                  afstand_stort_vandloeb_vaesentlig_ned
                  afstand_stort_vandloeb_vaesentlig_op
                  afstand_trafikvej_vaesentlig_ned
                  afstand_trafikvej_vaesentlig_op
                  afstand_vindmoelle_vaesentlig_ned
                  afstand_vindmoelle_vaesentlig_op
                  bad_toilet_vaesentlig_ned
                  bad_toilet_vaesentlig_op
                  beliggenhed_vaesentlig_ned
                  beliggenhed_vaesentlig_op
                  grundareal_vaesentlig_ned
                  grundareal_vaesentlig_op
                  grundform_vaesentlig_ned
                  grundform_vaesentlig_op
                  oevrige_ned
                  oevrige_op
                  ombygningsaar_vaesentlig_ned
                  ombygningsaar_vaesentlig_op
                  opfoerelsesaar_vaesentlig_ned
                  opfoerelsesaar_vaesentlig_op
                  skov_areal_vaesentlig_ned
                  skov_areal_vaesentlig_op
                  tagmateriale_vaesentlig_ned
                  tagmateriale_vaesentlig_op
                  udsigt_hav_vaesentlig_ned
                  udsigt_hav_vaesentlig_op
                  udsigt_soe_vaesentlig_ned
                  udsigt_soe_vaesentlig_op
                  vaegtet_areal_vaesentlig_ned
                  vaegtet_areal_vaesentlig_op
                  varme_suppl_vaesentlig_ned
                  varme_suppl_vaesentlig_op
                  varme_vaesentlig_ned
                  varme_vaesentlig_op
                  ydervaegsmateriale_vaesentlig_ned
                  ydervaegsmateriale_vaesentlig_op
                }
                justering_kvm_pris_op
                justering_kvm_pris_ned
                manuel_sagsbehandling_tillaeg_nedslag
                manuel_sagsbehandling_tillaeg_nedslag_oevrig
                oevrig_del_i_byzone
                oevrig_del_i_landzone
                skoennet_ejendomsvaerdi_helt
                skoennet_ejendomsvaerdi_helt_oevrig
                skoennet_ejendomsvaerdi_kvm_pris
                skoennet_ejendomsvaerdi_kvm_pris_oevrig
              }
              fordelt_areal_boligdel
              fordelt_areal_oevrigt
              fordelt_vaerdi
              fritekst_delvis_undtagelse
              generelt_vaesentlig {
                grp {
                  bad_toilet
                  tagmateriale
                  varme
                  varme_suppl
                  ydervaegsmateriale
                }
                vaegtet_areal
              }
              gennemsnitlig_kvm_pris_omraade
              grundareal
              justeringsprocenter {
                afstand_jernbane
                afstand_kyst
                afstand_motorvej_motortrafikvej
                afstand_station
                afstand_stor_soe
                afstand_stort_vandloeb
                afstand_trafikvej
                afstand_vindmoelle
                bad_toilet
                beliggenhed
                grundareal
                grundform
                oevrige
                ombygningsaar
                opfoerelsesaar
                skov_areal
                tagmateriale
                udsigt_hav
                udsigt_soe
                vaegtet_areal
                varme
                varme_suppl
                ydervaegsmateriale
              }
              kvm_pris_justeret
              ombygningsaar
              opfoerelsesaar
              ratio
              referenceejendomme {
                adresse
                fremskreven_kvm_pris
                generelt_vaesentlig {
                  grp {
                    bad_toilet
                    tagmateriale
                    varme
                    varme_suppl
                    ydervaegsmateriale
                  }
                  vaegtet_areal
                }
                salgsdato
                salgspris
                salgspris_fremskrevet
              }
              skoennet_ejendomsvaerdi_delvis_undtagelse
              skoennet_ejendomsvaerdi_efter
              skoennet_ejendomsvaerdi_efter_oevrig
              skoennet_ejendomsvaerdi_helt
              skoennet_ejendomsvaerdi_helt_fritekst
              skoennet_ejendomsvaerdi_helt_fritekst_oevrig
              skoennet_ejendomsvaerdi_helt_oevrig
              skoennet_ejendomsvaerdi_kvm_pris
              skoennet_ejendomsvaerdi_kvm_pris_fritekst
              skoennet_ejendomsvaerdi_kvm_pris_fritekst_oevrig
              skoennet_ejendomsvaerdi_kvm_pris_oevrig
              tillaeg_nedslag {
                fritekst
                vaerdi
                vaerdipaavirkende_forhold {
                  forhold
                }
                bilag {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
              }
              tillaeg_nedslag_oevrig {
                bilag {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
                fritekst_vm
                vaerdi
                vaerdipaavirkende_forhold_oevrig {
                  forhold_oevrig
                }
              }
              vaegtet_areal {
                bygninger {
                  areal
                  areal_imputeret
                  areal_vaegtet
                  arealanvendelse
                  vaegt
                }
                imputeret_boligareal_paa_delvurderingen
              }
              vaegtet_areal_samlet
              vaerdi_efter_tillaeg_nedslag
              vaerdi_efter_tillaeg_nedslag_oevrig
              vaerdi_foer_tillaeg_nedslag
              vaerdi_foer_tillaeg_nedslag_oevrig
              vaerdi_samlede_justeringer_kvm_pris
              vaerdi_samlede_justeringer_procent
              vaerdi_tillaeg_nedslag_samlet
              vaerdi_tillaeg_nedslag_samlet_oevrig
            }
            ejendomsvaerdi
            fritekst_tilbageregnet_ejendomsvaerdi
            grundareal
            grundform
            juridisk_kategori {
              kategori_id
              kategori_tekst
              underkategori_id
              underkategori_tekst
            }
            indekstal_2001
            indekstal_2002
            indekstal_2020
            indekstallets_myndighed
            kommune
            kommunespecifik_andel
            konkret_vaesentlig {
              adresse_afstand {
                jernbane {
                  afstand
                }
                kyst {
                  afstand
                }
                motorvej_motortrafikvej {
                  afstand
                }
                station {
                  afstand
                }
                stor_soe {
                  afstand
                }
                stort_vandloeb {
                  afstand
                }
                trafikvej {
                  afstand
                }
                vindmoelle {
                  afstand
                }
              }
              skov_areal
              udsigt_hav {
                udsigt_meter
              }
              udsigt_soe {
                udsigt_meter
              }
            }
            kvmpris_landbrugsjord
            kvmpris_rekreativ_areal
            skoennet_kategorisering
            skoennet_kategorisering_fritekst
            skoennet_type
            tilbageregnet_ejendomsvaerdi_2001
            tilbageregnet_ejendomsvaerdi_2002
            vaegtet_areal {
              arealer {
                areal
                areal_vaegtet
                arealanvendelse
                imputeret_vaerdi_for_arealanvendelsen
                vaegt
              }
              vaegtet_areal_samlet
            }
            vaerdi_oevrig
            vejareal
            vurderingsadresse
          }
          fletteflag {
            CAP {
              afstand_til_jernbane
              afstand_til_kyst
              afstand_til_motorvej_motortrafikvej
              afstand_til_station
              afstand_til_stor_soe
              afstand_til_stort_vandloeb
              afstand_til_trafikvej
              afstand_til_vindmoelle
              skov_areal
              udsigt_hav
              udsigt_soe
            }
            K4
            K5
            K7
            K20
            K24
            K32
            K46
            K47
            K48
            K49
            K50
            K52
            K62
            K69
            K70
            K71
            K74
            K75
            K87
            K113
            K114
            K160
            beliggenhed_imputeret
            beliggenhedsadresse_mangler
            begrundelse_til_EV_findes
            begrundelse_til_GV_findes
            begrundelse_vm_findes_for_gv
            byggetilladelse_ejerbolig_mindst_1_aar
            delvis_jordrentepligt
            undtaget_for_vurdering
            ev {
              delvis_undtagelse
              ejendom_delvist_undtaget_delvurderinger_ejendomsniveau
              ejendom_delvist_undtaget_en_delvurdering_ejendomsniveau
              ejendom_paa_5000kvm_i_landzone
              ejendommen_omfattet_af_ejendomsvaerdiskatteloven_ev
              ev_ansaettes
              ev_uden_beskatningsmaessigt_formaal
              flere_aarsager_til_tilbageregning_ejendomsvaerdi
              foerste_boligenhed_ikke_vist_referenceejendomme
              fritekst_tilbageregning_regulering
              hvis_ejerlejlighed
              hvis_parcelhus
              hvis_sommerhus
              hvis_tilbageregning_pba_ejerboligfordeling
              hvis_to_boligenheder
              imputeret_boligareal_paa_ejendomsniveau
              markering_for_godtgoerelse_ev
              reguleringsgrund_ev
              skoennet_ejendomsvaerdi_helt
              skoennet_ejendomsvaerdi_kvm_pris
              tilbageregning_for_samlet_vurdering_etableres
              tilbageregning_naar_ejerbolig_grundareal_over_5000_kvm_i_landzone
              tilbageregning_pba_aendring_af_grundareal
              tilbageregning_pba_ejendom_omfattet_af_pligt
              tilbageregning_pba_opfoert_nybyggeri
              tilbageregningsgrund_ev
              hvis_ejl_bygget_som_parcel
              offentlig_ejendom_kun_grundvaerdi
            }
            fritagelse_grundskyld_hel
            fritagelse_grundskyld_hel_manuel
            grundareal_imputeret
            grundform_imputeret
            gv {
              ejendom_min_en_delvist_fritaget_delgrund_ejendomsniveau
              ejendom_min_en_hel_fritaget_delgrund
              ejendom_offentlig_ejet_daekningsafgiftspligtig_forskelsvaerdien
              flere_fradrag_for_grundforbedringer
              flere_fradrag_reguleres_pba_tilbageregning
              fradrag_for_forbedringer
              fritekstfelt_aktiveret_gv
              fritliggende_boligenheder
              grund_opdelt_i_zoner
              gv_ansaettes
              gv_uden_beskatningsmaessigt_formaal
              hvis_dobbelthus
              hvis_flere_delvurderinger_grundniveau
              manuel_fastsaettelse_FFF
              manuel_regulering_gv
              markering_for_godtgoerelse_gv
              offentlig_ejendom_grundskyld_betales
              offentlig_ejendom_udlejes_erhverv
              reguleret_fradrag_pba_regulering_gv
              reguleret_fradrag_pba_tilbageregning_gv
              skoennet_grundvaerdi_helt
              skoennet_grundvaerdi_kvm_pris
              tilbageregning_pba_samlet_vurdering_etableres_aendres_ophoerer
              tilbageregningsgrund_gv
              to_familiehus_vandret_skel_paa_grunden
              uudnyttet_potentiale_moderejendom
              ejendom_offentlig_ejet_daekningsafgiftspligtig
            }
            ingen_boligenhed_delvurdering_frit_skoen
            juridisk_kategori_id_1100
            maks_en_boligenhed_delvurdering_frit_skoen
            manuel_sagsbehandling
            tomt_vurderingsforslag
            jord_i_flere_kommuner
            maskinel_kategorisering
            planlagt_anvendelse_udnyttelse_fra_bygningsregelmentet
            vejareal
            parcelhusmodel_anvendt_mindst_en_ev_dv
          }
          fritekst_ekstra
          grund {
            aflaest_gv_vaerdi_ud_fra_gvk
            basisaar_vurderingsejendom
            ejerlav {
              matrikler {
                matrikelnummer
              }
              navn
            }
            ejerlav_grundform
            begrundelse_til_EV
            begrundelse_til_GV
            begrundelse_fritekst_vm_for_gv
            fradrag_grundforbedringer {
              fradrag_startaar
              grundvaerdi
              vaerdi_2020
            }
            fradrag_grundforbedringer_samlet_vaerdi
            fradrag_grundforbedringer_vardi_minus_fritagelse
            fritagelse_grundskyld_hel_aarsag
            fritagelse_grundskyld_hel_fritekst
            fritekst
            grund_delvurderinger {
              aflaest_grundvaerdi
              aflaest_grundvaerdi_foerste_5000m2
              aflaest_grundvaerdi_identiske_5000m2
              aflaest_grundvaerdi_identiske_5000m2_mindre_end_eller_lig
              aflaest_gv_vaerdi_ud_fra_gvk_sejl
              annekser_areal_samlet
              antal_mulige_udstykninger
              anvendelse_udnyttelse_fritekst
              areal_efter_fritagelse
              areal_resterende_del
              areal_resterende_del_identiske_delbetragt
              bebyggelsesprocent
              bedste_anvendelse
              bedste_udnyttelse
              betragtninger {
                faktisk {
                  boligenheder {
                    enhedsnummer
                    faktisk_anvendelse_db
                    faktisk_etageareal_db
                  }
                }
                valgt {
                  boligenheder {
                    aflaest_grundvaerdi_db
                    areal_resterende_del_db
                    bebyggelsesprocent_db
                    bedste_anvendelse_db
                    bedste_udnyttelse_db
                    betydning_justeringer_samlet_db
                    bibygninger_areal_beregnet_db
                    enhedsnummer
                    fletteflag {
                      hvis_sommerhus_db
                    }
                    fordelt_oevrig_areal_begge_boligenheder
                    foreloebig_grundvaerdi_db
                    forskel_etageareal_db
                    forskel_restareal_db
                    grundareal
                    justeringsprocent_etageareal_db
                    justeringsprocent_restareal_db
                    ratio
                    restareal_db
                    vaerdi_af_landbrugsjord
                    vaerdi_samlede_justeringer_db
                    vaerdi_standardhus_db
                    vejareal_for_delbetragtning
                  }
                }
              }
              betydning_justeringer_samlet
              bibygninger {
                areal
                arealanvendelse
              }
              bibygninger_areal_beregnet
              bibygninger_areal_samlet
              bilag {
                valg_af_beregning {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
                kvadratmeterpris {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
                skoen {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
                fritagelse {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
                fordeling_af_jordrentepligt {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
              }
              bygningsareal_samlet
              delgrundsnummer
              ejerlav {
                matrikler {
                  matrikelnummer
                }
                navn
              }
              faktisk_anvendelse
              faktisk_etageareal
              fletteflag {
                K18
                K25
                K26
                K27
                K28
                K28s
                K29
                K30
                K31
                K33
                K34
                K34s
                K35
                K35s
                K36
                K37
                K38
                K42
                K43
                K43s
                K54
                K55
                K59
                K60
                K63
                K65
                K84
                K88
                K89
                K91
                K94
                K95
                K96
                K97
                K98
                K99
                K101
                K102
                K103
                K104
                K105
                K106
                K107
                K109
                K110
                K111
                K112
                K118
                K700
                anneksareal_paa_ejendom
                anvendelseskode_120_132_190_id_1100_2100
                anvendelseskode_130_131_140
                arealbibygninger_over_20m2
                arealbibygninger_over_25_procent
                arealbibygninger_over_50m2
                bibygningsareal_paa_ejendom
                bibygningsareal_paa_ejendom_vurdering
                del_af_delgrund_undtaget_helt_frit_skoen
                del_af_delgrund_undtaget_m2pris_frit_skoen
                delgrund_over_5000m2
                delvis_jordrentepligt
                delvis_undtagelse
                en_boligenhed_paa_delgrund
                enhedsanvendelseskode_110
                enhedsanvendelseskode_120
                enhedsanvendelseskode_121
                enhedsanvendelseskode_122
                enhedsanvendelseskode_130
                enhedsanvendelseskode_131
                enhedsanvendelseskode_132
                enhedsanvendelseskode_140
                enhedsanvendelseskode_185
                enhedsanvendelseskode_190
                enhedsanvendelseskode_510
                enhedsanvendelseskode_529
                enhedsanvendelseskode_585
                enhedsanvendelseskode_590
                flere_bibygninger_paa_delgrunden
                frit_skoen_m2pris_ik_fritaget_grundskyld
                fritagelse_grundskyld_delvis
                grund_for_boligenheder_overstiger_5000m2
                grundvaerdi_justeret_ned
                grundvaerdi_justeret_op
                hel_delvurdering_er_undtaget
                hel_undtagelse_gv
                helt_frit_skoen_ik_fritaget_grundskyld
                helt_fritaget_delgrund
                hvis_kun_en_frastykning
                hvis_kvm_pris_skoen_gv_boligareal_19a_stk_2_dv
                manuelt_skifte_planlagt_faktisk_anvendelse_udnyttelse
                planlagt_anvendelse
                planlagt_udnyttelse
                rekreativt_areal
                rekreativt_areal_i_landzone
                scenarie_1
                scenarie_1_1
                scenarie_2
                scenarie_3
                scenarie_3_1
                scenarie_4
                scenarie_5
                scenarie_6
                scenarie_6_1
                scenarie_7
                scenarie_7_1
                scenarie_8
                scenarie_9
                scenarie_9_1
                scenarie_10
                scenarie_11
                scenarie_12
                scenarie_12_1
                scenarie_13
                scenarie_14
                scenarie_14_1
                scenarie_15
                scenarie_15_1
                scenarie_16
                scenarie_17
                scenarie_18
                scenarie_19
                scenarie_20
                scenarie_21
                scenarie_22
                scenarie_23
                scenarie_24
                scenarie_25
                scenarie_26
                scenarie_27
                scenarie_27_1
                scenarie_28
                scenarie_28_1
                scenarie_29
                scenarie_30
                scenarie_30_1
                scenarie_31
                scenarie_31_1
                scenarie_32
                scenarie_33
                scenarie_33_1
                scenarie_34
                scenarie_34_1
                scenarie_35
                scenarie_35_1
                scenarie_36
                scenarie_36_1
                scenarie_37
                scenarie_38
                scenarie_39
                scenarie_39_1
                scenarie_40
                scenarie_40_1
                scenarie_41
                scenarie_42
                scenarie_43
                scenarie_44
                scenarie_45
                scenarie_46
                scenarie_47
                scenarie_48
                scenarie_49
                scenarie_50
                scenarie_51
                scenarie_52
                scenarie_53
                scenarie_54
                scenarie_55
                scenarie_56
                scenarie_57
                scenarie_58
                scenarie_59
                scenarie_60
                scenarie_61
                scenarie_62
                scenarie_63
                scenarie_64
                skoennet_ejendomsvaerdi_helt_gv
                skoennet_ejendomsvaerdi_kvm_pris_gv
                skoennet_grundvaerdi_helt
                skoennet_grundvaerdi_kvm_pris
                to_boligenheder_paa_delgrund
                udstykning_over_5000m2_delgrund
                udstykningsmuligheder
                vejareal_paa_delgrund
                andre_19_a_paa_moderejendom_delgrund
                hvis_skoen_tilladt_etageareal
              }
              foreloebig_grundvaerdi
              foreloebig_grundvaerdi_foerste_5000
              foreloebig_grundvaerdi_foerste_5000_identiske_db
              foreloebig_vaerdi_udstykket
              forskel_etageareal
              forskel_restareal
              fritagelse {
                kvm_pris_ikke_fritaget_areal
              }
              fritagelse_grundskyld_delvis_fritekst
              grundareal_for_delgrunden
              grundvaerdi_efter_fritagelse
              jordrentepligt_fordeling {
                fritekst
              }
              justeringsprocent_etageareal
              justeringsprocent_restareal
              mindstegrundstoerrelse_valgt
              plangrundlag {
                navn
              }
              restareal
              skoennet_grundvaerdi_efter
              skoennet_grundvaerdi_helt
              skoennet_grundvaerdi_helt_fritekst
              skoennet_grundvaerdi_kvm_pris
              skoennet_grundvaerdi_kvm_pris_fritekst
              stoerrelse_rekreativt_areal
              tillaeg_nedslag {
                fritekst
                vaerdi
                vaerdipaavirkende_forhold {
                  forhold
                }
                bilag {
                  deklarationsmeddelelse
                  dokument_id
                  vedhaeftet_fil
                  vurderingsmeddelelse
                }
              }
              undtagelse {
                grundvaerdi_efter_skoennet_m2pris
                grundvaerdi_fritekst
                skoennet_grundvaerdi
                undtagelse_kvm_pris
              }
              vaerdi_af_jordrentepligtige_del_af_grunden
              vaerdi_af_landbrugsjord
              vaerdi_efter_tillaeg_nedslag
              vaerdi_rekreativt_areal
              vaerdi_samlede_justeringer
              vaerdi_standardhus
              vaerdi_tillaeg_nedslag_samlet
              vejareal_for_delgrunden
              zonestatus
            }
            grundvaerdi
            grundvaerdi_efter_fritagelse
            grundvaerdi_etageejl_uden_pot_tillaeg_nedslag
            jordrentepligt_fritekst
            jordrentepligtig_dels_fff_andel
            jordrentepligtvaerdi
            matrikelnummer_grundform
            reguleret_fradrag_pba_tilbage_regulering
            tilbageregnet_grundvaerdi
            tilbageregnet_grundvaerdi_fritekst
            vaerdi_samlet_fradrag_pba_tilbage_regulering
            daekningsafgiftspligtig_grundvaerdi
          }
          metainformation {
            correlation_id
            schema
          }
          undtagelse {
            fritekst
          }
          vurderingsejendom_id
        }
        dokumentgruppe
      }
    }
    revurderingssager @include(if: $visRichText) {
      repraesenteret
      revurdering {
        datagrundlag {
          grund {
            grundvaerdi
            grund_delvurderinger {
              antal_mulige_udstykninger
              bedste_anvendelse
              bedste_udnyttelse
              foreloebig_vaerdi_udstykket
              mindstegrundstoerrelse_valgt
            }
          }
          ejendomsoplysninger {
            ejendomsvaerdi
            grundareal
          }
        }
      }
    }
  }
`;

const RichtextDatapointsFragment = gql`
  fragment Richtextdatapoints on Vurderingsejendom {
    vurderingsejendom_id
    tilbagebetalingssag {
      tilbagebetaling {
        dokumentgruppe
        brevdato
      }
      gentilbagebetaling {
        dokumentgruppe
        brevdato
      }
    }
    vurderingssager(vurderingsaar: $vurderingsaar) {
      deklarationer {
        brevdato
        datagrundlag {
          vurderingsejendom_id_ice
        }
      }
      sagsdata {
        frister {
          deklarationsperiode_slutdato
          afgoerelsesperiode_slutdato
        }
        vurderingstermin {
          vurderingstermindato
          vurderingsaar
        }
      }
      vurdering {
        brevdato
        datagrundlag {
          grund {
            grundvaerdi
            grund_delvurderinger {
              bedste_anvendelse
              bedste_udnyttelse
              mindstegrundstoerrelse_valgt
              foreloebig_vaerdi_udstykket
              antal_mulige_udstykninger
            }
          }
          ejendomsoplysninger {
            ejendomsvaerdi
            grundareal
          }
        }
      }
      klagefristbrev {
        brevdato
      }
      dato_for_sidst_indsendte_indsendelse(type: indsigelse)
    }
    historisk_vurderingssag {
      historisk_vurdering_klagefristbrev {
        brevdato
      }
    }
  }
`;

export const VURDERINGSEJENDOM_FULL_QUERY = gql`
  ${TilbagebetalingssagFragment}
  ${DeklarationFragment}
  ${InsendelserFragment}
  ${KlagefristbrevFragment}
  ${SagsdataFragment}
  ${VurderingFragment}
  ${RevurderingssagerFragment}
  ${HistoriskVurderingssagFragment}

  query GetVurderingsejendom(
    $visSagsdata: Boolean = false
    $visIndsendelser: Boolean = false
    $visVurdering: Boolean = false
    $visRevurderingssager: Boolean = false
    $visDeklarationer: Boolean = false
    $visDatagrundlag: Boolean = false
    $visKlagefristbrev: Boolean = false
    $visHistoriskVurderingssag: Boolean = false
    $visReferenceejendomme: Boolean = false
    $visRichText: Boolean = false
    $visHeroBillede: Boolean = false
    $imageWidth: Int = 1440
    $vurderingsaar: [Int] = null
    $vurderingsejendom_id: Int! = -1
    $indsendelseType: IndsendelseType = indsigelse
  ) {
    vurderingsejendom(vurderingsejendom_id: $vurderingsejendom_id) {
      vurderingsejendom_id
      ...Tilbagebetalingssag
      ...HistoriskVurderingssag
      vurderingssager(vurderingsaar: $vurderingsaar) {
        ...Deklaration
        ...Insendelser
        ...Klagefristbrev
        ...Sagsdata
        ...Vurdering
        ...Revurderingssager
        repraesenteret
        repraesentation {
          dokumenter {
            dokumentgruppe
            titel
          }
          privilegie
        }
        hero_billede(bredde: $imageWidth) @include(if: $visHeroBillede) {
          kortudsnit
          stoerrelse {
            bredde
            hoejde
          }
          url
        }
        sag_id
        vurderingsejendom_id
        vurderingsaar
        aaben_for_indsigelser
        dato_for_sidst_indsendte_indsendelse(type: $indsendelseType)
        indsigelsesperiode {
          lukket_aarsag
          tilstand
        }
      }
      dokumenter {
        bilag {
          dokument
          dokument_id
          dokumentgruppe
          mime_type
          titel
          udsendelsesdato
        }
        brevdato
        dokument
        dokumentgruppe
        titel
        mime_type
        sag_id
        titel
      }
    }
  }
`;

export const VURDERINGSEJENDOMME_MY_PROPERTIES = gql`
  ${RichtextDatapointsFragment}

  query GetVurderingsejendomme(
    $paging: VurderingsejendommePagingInput
    $vurderingsaar: [Int] = null
    $vurderingsejendomme_ids: [Int] = null
  ) {
    fetch_vurderingsejendomme_by_ids(
      paging: $paging
      vurderingsejendomme_ids: $vurderingsejendomme_ids
    ) {
      limit
      offset
      order
      sorting
      total_count
      values {
        ...Richtextdatapoints
        vurderingsejendom_id
        seneste_dokument_aktivitet
        tilbagebetalingssag {
          vurderingsejendom_id
          repraesenteret
          sagsdata {
            bfenumre
            vurderingsejendom_adresse {
              adressebetegnelse
              doer
              etage
              husnr
              postnr
              postnr_navn
              supplerende_bynavn
              vejnavn
            }
          }
          tilbagebetaling {
            datagrundlag {
              brevdata {
                datapunkter {
                  begrundelse
                }
              }
            }
            dokumentgruppe
            brevdato
          }
          gentilbagebetaling {
            dokumentgruppe
            brevdato
          }
        }
        historisk_vurderingssag {
          vurderingsejendom_id
          repraesenteret
          vurderingsaar
          sagsdata {
            bfenumre
            vurderingsejendom_adresse {
              adressebetegnelse
              doer
              etage
              husnr
              postnr
              postnr_navn
              supplerende_bynavn
              vejnavn
            }
          }
          historisk_vurdering_klagefristbrev {
            brevdato
          }
        }
        vurderingssager(vurderingsaar: $vurderingsaar) {
          deklarationer {
            brevdato
            dokumentgruppe
            datagrundlag {
              BFEnumre {
                BFEnummer
              }
              fletteflag {
                beliggenhedsadresse_mangler
                undtaget_for_vurdering
              }
              vurderingsejendom_id_ice
            }
          }
          vurdering {
            brevdato
            dokumentgruppe
            datagrundlag {
              BFEnumre {
                BFEnummer
              }
              vurderingsejendom_id
              fletteflag {
                beliggenhedsadresse_mangler
                undtaget_for_vurdering
              }
            }
          }
          klagefristbrev {
            brevdato
          }
          sagsdata {
            frister {
              deklarationsperiode_slutdato
            }
            bfenumre
            vurderingsejendom_adresse {
              adressebetegnelse
              doer
              etage
              husnr
              postnr
              postnr_navn
              supplerende_bynavn
              vejnavn
            }
            sagsstatus {
              styringskoder
              statuskode
            }
          }
          revurderingssager {
            repraesenteret
            revurdering {
              dokumentgruppe
              datagrundlag {
                vurderingsejendom_id
                fletteflag {
                  undtaget_for_vurdering
                }
              }
            }
          }
          repraesenteret
          repraesentation {
            dokumenter {
              dokumentgruppe
              titel
            }
            privilegie
          }
          sag_id
          vurderingsejendom_id
          vurderingsaar
          indsendelser {
            indsendelse_id
            indhold
            type
            tilstand
          }
          indsigelsesperiode {
            tilstand
          }
        }
      }
    }
  }
`;

export const GET_INDSENDELSER = gql`
  query GetIndsendelser(
    $vurderingsaar: [Int] = []
    $vurderingsejendom_id: Int! = -1
    $type: IndsendelseType = indsigelse
  ) {
    vurderingsejendom(vurderingsejendom_id: $vurderingsejendom_id) {
      vurderingsejendom_id
      vurderingssager(vurderingsaar: $vurderingsaar) {
        aaben_for_indsigelser
        dato_for_sidst_indsendte_indsendelse(type: $type)
        vurderingsejendom_id
        vurderingsaar
        sag_id
        indsendelser {
          bilag {
            bilag_id
            mime_type
            navn
            opdateret
            oprettet
            stoerrelse
            tilstand
          }
          indhold
          indsendelse_id
          opdateret
          oprettet
          tilstand
          type
          vurderingsaar
          vurderingsejendom_id
        }
        indsigelsesperiode {
          lukket_aarsag
          tilstand
        }
        repraesenteret
        sagsdata {
          frister {
            deklarationsperiode_slutdato
          }
        }
      }
    }
  }
`;

export const GET_BILAG = gql`
  query getBilag($indsendelse_id: UUID!) {
    indsendelse(indsendelse_id: $indsendelse_id) {
      bilag {
        bilag_id
        mime_type
        navn
        opdateret
        oprettet
        stoerrelse
        tilstand
      }
    }
  }
`;

export const VURDERINGSADRESSER = gql`
  query GetVurderingsadresser {
    vurderingsadresser {
      adresse {
        adressebetegnelse
        doer
        etage
        husnr
        postnr
        postnr_navn
        supplerende_bynavn
        vejnavn
      }
      vurderingsejendom_id
    }
  }
`;
